const countries = require('../timeZoneDatabase')

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

let hboUrl;
if(countries.spain.includes(timeZone)) {
  hboUrl = 'https://es.hboespana.com/'
} else if(countries.nordic.includes(timeZone)) {
  hboUrl = 'https://hbonordic.com/'
} else if(countries.latinAmerica.includes(timeZone)) {
  hboUrl = 'https://www.hbolatam.com/'
} else if(countries.caribbean.includes(timeZone)) {
  hboUrl = 'https://www.hbocaribbean.com/'
} else if (countries.asia.includes(timeZone)) {
  hboUrl = 'https://www.hbogoasia.com/';
} else if(countries.portugal.includes(timeZone)) {
  hboUrl = 'https://hboportugal.com/'
} else if(countries.brazil.includes(timeZone)) {
  hboUrl = 'https://www.hbobrasil.com/'
} else if(countries.hyngary.includes(timeZone)) {
  hboUrl = 'https://hbogo.hu/'
} else if(countries.poland.includes(timeZone)) {
  hboUrl = 'https://hbogo.pl/'
} else if(countries.czechRepublic.includes(timeZone)) {
  hboUrl = 'https://hbogo.cz/'
} else if(countries.slovakia.includes(timeZone)) {
  hboUrl = 'https://hbogo.sk/'
} else if(countries.romania.includes(timeZone)) {
  hboUrl = 'https://hbogo.ro/'
} else if(countries.bulgaria.includes(timeZone)) {
  hboUrl = 'https://hbogo.bg/'
} else if(countries.bosnia.includes(timeZone)) {
  hboUrl = 'https://hbogo.ba/'
} else if(countries.croatia.includes(timeZone)) {
  hboUrl = 'https://hbogo.hr/'
} else if(countries.slovenia.includes(timeZone)) {
  hboUrl = 'https://hbogo.si/'
} else if(countries.montenegro.includes(timeZone)) {
  hboUrl = 'https://hbogo.me/'
} else if(countries.serbia.includes(timeZone)) {
  hboUrl = 'https://hbogo.rs/'
} else if(countries.northMacedonia.includes(timeZone)) {
  hboUrl = 'https://hbogo.mk/'
} else {
  hboUrl = 'https://www.hbo.com/'
}

export const hbo = {
  name: 'HBO',
  id: 'hbo',
  url: hboUrl,
  color: '#202020',
  compatible: false
}
