import MediaFrame from './media-frame';
import style from './style.css';

const App = () => {
	console.log(
		'%cDesigned with love for your %cTesla 💚',
		'color: #CCFF90; font-size:20px;',
		'color: #FF8A80; font-size:20px;'
	);

	return (
		<div id="app" class={style.app}>
			<MediaFrame />
		</div>
	)
}

export default App;
