export const spain = [
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Madrid'
]
export const nordic = [
  'America/Danmarkshavn',
  'America/Nuuk',
  'America/Scoresbysund',
  'America/Thule',
  'Atlantic/Faeroe',
  'Atlantic/Faroe',
  'Atlantic/Jan_Mayen',
  'Atlantic/Reykjavik',
  'Europe/Copenhagen',
  'Europe/Helsinki',
  'Europe/Mariehamn',
  'Europe/Oslo',
  'Europe/Stockholm',
  'Iceland'
]
export const latinAmerica = [
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Asuncion',
  'America/Bahia_Banderas',
  'America/Belize',
  'America/Bogota',
  'America/Buenos_Aires',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Chihuahua',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Hermosillo',
  'America/Jujuy',
  'America/La_Paz',
  'America/Lima',
  'America/Managua',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Merida',
  'America/Mexico_City',
  'America/Monterrey',
  'America/Montevideo',
  'America/Ojinaga',
  'America/Panama',
  'America/Paramaribo',
  'America/Punta_Arenas',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santiago',
  'America/Tegucigalpa',
  'America/Tijuana',
  'Atlantic/Jan_Mayen',
  'Atlantic/South_Georgia',
  'Atlantic/Stanley',
  'Chile/Continental',
  'Chile/EasterIsland',
  'Etc/GMT+2',
  'Europe/Oslo',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
  'Pacific/Easter',
  'Pacific/Galapagos'
]
export const caribbean = [
  'America/Anguilla',
  'America/Antigua',
  'America/Aruba',
  'America/Barbados',
  'America/Cayman',
  'America/Curacao',
  'America/Dominica',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Havana',
  'America/Jamaica',
  'America/Kralendijk',
  'America/Lower_Princes',
  'America/Marigot',
  'America/Martinique',
  'America/Montserrat',
  'America/Nassau',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Puerto_Rico',
  'America/Santo_Domingo',
  'America/St_Barthelemy',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Tortola',
  'America/Virgin',
  'Cuba',
  'Jamaica'
]
export const asia = [
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Ashkhabad',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Choibalsan',
  'Asia/Chongqing',
  'Asia/Chungking',
  'Asia/Colombo',
  'Asia/Dacca',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Harbin',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Istanbul',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Karachi',
  'Asia/Kashgar',
  'Asia/Kathmandu',
  'Asia/Katmandu',
  'Asia/Kolkata',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macao',
  'Asia/Macau',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qostanay',
  'Asia/Qyzylorda',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Tel_Aviv',
  'Asia/Thimbu',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Ujung_Pandang',
  'Asia/Ulaanbaatar',
  'Asia/Ulan_Bator',
  'Asia/Urumqi',
  'Asia/Vientiane',
  'Asia/Yangon',
  'Asia/Yerevan',
  'Europe/Istanbul',
  'Europe/Nicosia',
  'Hongkong',
  'Indian/Maldives',
  'Iran',
  'Israel',
  'Japan',
  'PRC',
  'ROC',
  'ROK',
  'Turkey'
]
export const portugal = [
  'Portugal',
  'Atlantic/Azores',
  'Atlantic/Madeira',
  'Europe/Lisbon'
]
export const brazil = [
  'Brazil/West',
  'Brazil/DeNoronha',
  'America/Porto_Acre',
  'Brazil/Acre',
  'Brazil/East',
  'America/Araguaina',
  'America/Bahia',
  'America/Belem',
  'America/Boa_Vista',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Eirunepe',
  'America/Fortaleza',
  'America/Maceio',
  'America/Manaus',
  'America/Noronha',
  'America/Rio_Branco',
  'America/Porto_Velho',
  'America/Recife',
  'America/Santarem',
  'America/Sao_Paulo'
]
export const hyngary = [
  'Europe/Budapest'
]
export const poland = [
  'Poland',
  'Europe/Warsaw'
]
export const czechRepublic = [
  'Europe/Prague'
]
export const slovakia = [
  'Europe/Bratislava',
  'Europe/Prague'
]
export const romania = [
  'Europe/Bucharest'
]
export const bosnia = [
  'Europe/Sarajevo',
  'Europe/Belgrade'
]
export const bulgaria = [
  'Europe/Sofia'
]
export const croatia = [
  'Europe/Zagreb',
  'Europe/Belgrade'
]
export const slovenia = [
  'Europe/Ljubljana',
  'Europe/Belgrade'
]
export const montenegro = [
  'Europe/Podgorica',
  'Europe/Belgrade'
]
export const serbia = [
  'Europe/Belgrade'
]
export const northMacedonia = [
  'Europe/Skopje',
  'Europe/Belgrade'
]
