import style from './style.css';
import Media from '../media';
import { hbo } from '../../assets/media-types/hbo'
import { netflix } from '../../assets/media-types/netflix'
import { disney } from '../../assets/media-types/disney'
import { youtube } from '../../assets/media-types/youtube'
import { google } from '../../assets/media-types/google'
import { twitch } from '../../assets/media-types/twitch'
import { plex } from '../../assets/media-types/plex'
import { prime } from '../../assets/media-types/prime'
import { rakuten } from '../../assets/media-types/rakuten'

const allMedia = [
  netflix,
  hbo,
  prime,
  youtube,
  twitch,
  disney,
  google,
  rakuten,
  plex
]

const MediaFrame = () => (
  <div class={style.mediaFrame}>
    <div class={style.mediaGrid}>
      {allMedia.map((media, i) => <Media key={i} type={media} />)}
    </div>
  </div>

);

export default MediaFrame;
