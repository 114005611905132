import style from './style.css';

const redirect = (media) => {
  let url = media.url

  if (!media.compatible) {
    url = `https://www.youtube.com/redirect?q=${url}`
  }

  window.location.assign(url)
}

const Media = (props) => {
  const media = props.type

  return (
    <div
      class={style.media}
      style={{background: media.color}}
      onclick={() => redirect(media)}
    >
      <div>
        <img src={`/assets/media-icons/media.svg#${media.id}`} alt={media.name} />
      </div>
    </div>
  );
};

export default Media;
